import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFacebookF,
  faTwitter,
  faYoutube,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons"

const contact = () => {
  return (
    <div>

      <div className="section section-lg pt-5">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-8 px-4 mb-5 mb-lg-0">
              <h5 className="mb-3">Our social media</h5>
              <div className="row">
                <div className="col-3">
                  <a
                    className="font-weight-bold text-primary d-flex"
                    href="https://www.youtube.com/channel/UCNYxbOqC8ggvUhL9-wjmErQ"
                    target="_blank"
                  >
                    <div className="px-2 mr-2 rounded btn-youtube">
                      <FontAwesomeIcon icon={faYoutube} />
                    </div>
                    Youtube
                  </a>
                </div>
                <div className="col-3">
                  <a
                    className="font-weight-bold text-primary d-flex"
                    href="https://facebook.com/rwandahealthinitiative"
                    target="_blank"
                  >
                    <div className="px-2 mr-2 rounded btn-facebook">
                      <FontAwesomeIcon icon={faFacebookF} />
                    </div>
                    Facebook
                  </a>
                </div>
                <div className="col-3">
                  <a
                    className="font-weight-bold d-flex"
                    href="https://instagram.com/rhiyw_rwanda?igshid=YmMyMTA2M2Y="
                    target="_blank"
                  >
                    <div className="px-2 mr-2 rounded btn-instagram">
                      <FontAwesomeIcon icon={faInstagram} />
                    </div>
                    Instagram
                  </a>
                </div>
                <div className="col-3">
                  <a
                    className="font-weight-bold text-primary d-flex"
                    href="https://twitter.com/rhiwomen"
                    target="_blank"
                  >
                    <div className="px-2 mr-2 rounded btn-twitter">
                      <FontAwesomeIcon icon={faTwitter} />
                    </div>
                    Twitter
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default contact
